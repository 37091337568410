import React from 'react';
import './App.scss';
import ImageCarousel from "./ImageCarousel";

function App() {
  return (
    <div className="App">
      <ImageCarousel/>
    </div>
  );
}

export default App;
