import './ImageCarousel.scss'
import React, {Component} from 'react';
import {Carousel, CarouselControl, CarouselItem} from 'reactstrap';

function importAllImgs(r) {
  return r.keys().map(r);
}

const images = importAllImgs(require.context('../src/res/imgs/', false, /\.(png|jpe?g|JPG)$/));
images.reverse();

const items = [];
images.forEach(img => {
  items.push({
    src: img,
    altText: 'Slide ',
    caption: 'Slide'
  })
})

export default class ImageCarousel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({activeIndex: nextIndex});
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({activeIndex: nextIndex});
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({activeIndex: newIndex});
  }

  render() {
    const {activeIndex} = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img src={item.src} alt={item.altText}/>
        </CarouselItem>
      );
    });

    return (
      <div className='ImageCarousel'>
        <div className='textContainer'>
          <h1>one MUST love.</h1>
        </div>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous}/>
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next}/>

        </Carousel>

        <div className='textContainer'>
          <h1>Gutschein für 1 Fotoshooting</h1>
          <p>by Fabiano</p>
        </div>
      </div>
    );
  }
}